import { useState, useMemo, useCallback } from 'react';

import { useKeys } from '@/contexts/KeysContext';
import { parseFromUrl, serializeData } from '@/utils/serializer';
import { setFieldFactory } from '@/utils/set-field-factory';
import { OptionOfferState } from '@/contexts/OfferContext';

import { propertyOrders, defaults, otherDefaults } from '../constants/defaults';
import { calculatePay, getCalculated } from '../Croatia.utils';
import { IHrInputData } from '../interfaces/IHrInputData';
import { IHrOutputData } from '../interfaces/IHrOutputData';
import { Brand } from '../../../../enums/Brand';
import { TYear } from '../interfaces/TYear';

export function useTaxData(
  brand: Brand,
  offerFields?: ReadonlyArray<string>,
  options?: Record<string, unknown>,
  urlData: string = window.location.hash.slice(1),
) {
  const year = (options?.year as TYear) ?? (new Date().getFullYear() as TYear);
  const brandDefaults = useMemo(() => (brand === Brand.Infinum ? defaults : otherDefaults), [brand]);
  const DEFAULT_STATE = useMemo(
    () =>
      serializeData(
        propertyOrders,
        brandDefaults,
        () => {
          /* noop */
        },
        brand,
      ),
    [brand, brandDefaults],
  );
  const { setKey } = useKeys();
  const [inputData, setInputData] = useState(() =>
    parseFromUrl(propertyOrders, brandDefaults, brand, offerFields, urlData),
  );
  const [setField] = useState(() => setFieldFactory<IHrInputData>(setInputData));

  const calculated: IHrOutputData = useMemo(() => calculatePay(inputData, year), [inputData, year]);

  const serialize = useCallback(
    (offerState?: Record<string, OptionOfferState>) => {
      return serializeData(propertyOrders, inputData, setKey, brand, 'hr', offerState, offerFields);
    },
    [offerFields, inputData, setKey, brand],
  );

  const defaultState = useMemo(() => DEFAULT_STATE === urlData, [urlData, DEFAULT_STATE]);

  const state = useMemo(
    () => ({
      ...inputData,
      calculated: getCalculated(inputData, calculated, year, options?.legacy as boolean),
      defaultState,
    }),
    [calculated, inputData, defaultState, options?.legacy, year],
  );

  return { state, setField, serialize };
}

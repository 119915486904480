import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AddOn } from './AddOn';
import { SectionHeader } from './SectionHeader';
import { Segment } from './Segment';

export const AddOns = ({
  state,
  setField,
  title,
  children,
  items,
}: {
  state: { options?: Record<string, { flag: boolean; value: number }> };
  setField(name: string, value: number | boolean, enabled?: boolean): void;
  title: string;
  children?: ReactNode;
  items: Array<{ key: string; label: string }>;
}) => {
  const { t } = useTranslation();

  return (
    <Segment fields={items.map(({ key }) => key)}>
      <SectionHeader title={title}>{children}</SectionHeader>
      {items.map(({ key, label }) => (
        <AddOn
          key={key}
          name={key}
          disabled={!state.options?.[key].flag}
          amount={state.options?.[key].value ?? 0}
          title={t(`${label}.title`)}
          description={t(`${label}.info`).trim()}
          onToggle={(e: boolean) => setField(key, e, true)}
          onChange={(e: number) => setField(key, e, true)}
        />
      ))}
    </Segment>
  );
};

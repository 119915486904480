import { roundMoney } from '../../../utils/formatters';
import { isInReadonlyArray } from '../../../utils/is-in-readonly-array';
import {
  KOEF_BROJ_DJECE,
  BASE_ODBITAK,
  UZDRZAVANI,
  INVALIDNOST,
  INVALIDNOST_100,
  UNTAXABLE,
  LIMIT_TAX_HIGH,
  LIMIT_DOPRINOS,
  COUNTIES,
} from './constants/calculator';
import { AGE_COEF } from './constants/constants';
import { IHrInputData } from './interfaces/IHrInputData';
import { IHrOutputData } from './interfaces/IHrOutputData';
import { TYear } from './interfaces/TYear';
import { perks as perksList } from '@/constants/perks';

function getOsobniOdbitak(brojDjece: number, brojUzdrzavanih: number, invalidnost: number, year: TYear): number {
  const koef = KOEF_BROJ_DJECE[year];
  const base = BASE_ODBITAK[year];
  const uzdrzavaniKoef = UZDRZAVANI[year];
  const invalidnostKoef = INVALIDNOST[year];
  const invalidnost100Koef = INVALIDNOST_100[year];
  const koefdjeca = koef.slice(0, brojDjece).reduce((acc, curr) => acc + curr, 0);

  return (
    roundMoney(base * koefdjeca) +
    roundMoney(base * brojUzdrzavanih * uzdrzavaniKoef) +
    (invalidnost === 1 ? invalidnostKoef : 0) +
    (invalidnost === 2 ? invalidnost100Koef : 0)
  );
}

function getDiff(year: TYear, bruto1: number, maxUntaxable: number, untaxable: number) {
  if (year !== 2023 && untaxable === maxUntaxable) {
    if (bruto1 < 700) {
      // "Radnicima s plaćom do 700 eura osnovica se umanjuje za 300 eura"
      return 300;
    } else if (bruto1 < 1300) {
      // "ako mjesečna plaća iznosi od 700 do 1300 eura, osnovica se umanjuje za polovinu
      // razlike između radnikove plaće i 700 eura"
      return roundMoney((1300 - bruto1) / 2);
    }
  }

  return 0;
}

function getTaxRates(year: TYear, opcina: number) {
  if (year !== 2023) {
    const county = COUNTIES[year].find((item) => item.id === opcina);

    return {
      stopaPrireza: 0,
      stopaPorezaLow: (county?.rateLow ?? 0) / 100,
      stopaPorezaHigh: (county?.rateHigh ?? 0) / 100,
    };
  } else {
    const county = COUNTIES[2023].find((item) => item.id === opcina);
    const indexRaz = county?.special ? 0.5 : 1;

    return {
      stopaPrireza: county?.rate || 0,
      stopaPorezaLow: 0.2 * indexRaz,
      stopaPorezaHigh: 0.3 * indexRaz,
    };
  }
}

export function getNontaxable(inputData: IHrInputData) {
  return Object.values({ ...inputData.options, obustave: { flag: false, value: 0 } })
    .filter(({ flag }) => flag)
    .reduce((acc, curr) => acc + curr.value, 0);
}

export function calculatePay(
  inputData: IHrInputData,
  year: TYear,
  untaxableVal?: number,
  limitLower?: number,
): IHrOutputData {
  const untaxable = untaxableVal ?? UNTAXABLE[year];
  const neoporezivo = getNontaxable(inputData);
  const bruto1 = inputData.values.total - neoporezivo;
  const opcina = inputData.values.stopa;
  const limitTaxHigh = limitLower ?? LIMIT_TAX_HIGH[year];

  const diff = getDiff(year, bruto1, UNTAXABLE[year], untaxable);
  const dohodak = roundMoney(bruto1 - Math.min(bruto1 - roundMoney(diff * 0.75), LIMIT_DOPRINOS[year]) * 0.2);
  const odbitak =
    untaxable +
    getOsobniOdbitak(inputData.values.brojDjece, inputData.values.brojUzdrzavanih, inputData.values.invalidnost, year);

  const oporezivo = Math.max(0, dohodak - odbitak);
  const osnovicaLow = Math.min(limitTaxHigh, oporezivo);
  const osnovicaHigh = Math.max(0, oporezivo - limitTaxHigh);
  const taxRates = getTaxRates(year, opcina);
  const porez = roundMoney(osnovicaLow * taxRates.stopaPorezaLow) + roundMoney(osnovicaHigh * taxRates.stopaPorezaHigh);
  const prirez = roundMoney((porez * taxRates.stopaPrireza) / 100);
  const porezprirez = porez + prirez;
  const brutoDoprinosi = roundMoney(bruto1 * 0.165);

  return {
    ...taxRates,
    osnovicaLow,
    osnovicaHigh,
    porez,
    prirez,
    neto: dohodak - porezprirez,
    oporezivo,
    odbitak,
    dohodak,
    brutoDoprinosi,
    neoporezivo,
    bruto1,
    bruto2: bruto1 + brutoDoprinosi,
    opcina,
    totalPay: inputData.values.total,
  };
}

export function calculateSpecific(inputData: IHrInputData, data: IHrOutputData) {
  const obustave = inputData.options.obustave.flag ? inputData.options.obustave.value : 0;
  const perks = perksList
    .filter(({ countries }) => isInReadonlyArray('hr', countries))
    .map(({ key }) => inputData.options[key])
    .filter(({ flag }) => flag)
    .reduce((acc, curr) => acc + curr.value, 0);
  const brutoDoprinosi = inputData.flags.mladaOsoba ? 0 : data.brutoDoprinosi;
  const doprinosi = data.bruto1 - data.dohodak;
  const porezi = parseFloat((data.porez + data.prirez).toFixed(2));
  const treciStup = inputData.options.treci.flag ? inputData.options.treci.value || 0 : 0;

  return {
    ...data,
    total: inputData.values.total,
    neoporezivo: data.neoporezivo - perks,
    brutoDoprinosi,
    doprinosi,
    porezi,
    odbitakCalc: Math.min(data.odbitak, data.bruto1 - doprinosi),
    davanja: doprinosi + porezi + brutoDoprinosi,
    treciStup,
    trosak: inputData.values.total + brutoDoprinosi,
    racun: data.neto + data.neoporezivo - treciStup - perks - obustave,
    povrat: (data.porez + data.prirez) * AGE_COEF[inputData.values.age],
    naknada: treciStup || perks ? data.neto + data.neoporezivo : 0,
    perks,
    obustave,
  };
}

import { Box, useColorModeValue, Text } from '@chakra-ui/react';
import * as React from 'react';

import { MoneyInput } from '@/components/shared/MoneyInput';
import { EntryRow } from '@/components/shared/EntryRow';
import { Eur } from '@/constants/currencies';
import { convertCurrency } from '@/utils/convert-currency';
import { useCurrencies } from '@/contexts/CurrencyContext';
import { useOffers } from '@/contexts/OfferContext';

export const AddOn = ({
  onChange,
  onToggle,
  title,
  description,
  amount,
  disabled,
  name,
}: {
  title: string;
  description?: string;
  amount: number;
  disabled?: boolean;
  name: string;
  onChange: (value: number) => void;
  onToggle: (e: boolean) => void;
}) => {
  const textColor = useColorModeValue('black', 'white');

  const { currency: primary, secondary, local } = useCurrencies();

  const { getFieldOfferState } = useOffers();
  const { isHidden, isReadOnly, isReadOnlyValue } = getFieldOfferState(name);

  if (isHidden) {
    return null;
  }

  const currency = primary || Eur;

  return (
    <EntryRow
      title={title}
      description={description}
      enabled={!disabled}
      readonly={isReadOnly}
      onChange={(e) => onToggle(e.target.checked)}
      name={name}
    >
      <Box alignItems="flex-end" display="flex" flexDirection="column">
        <MoneyInput
          step={currency.step}
          value={convertCurrency(amount, local, currency)}
          isDisabled={disabled || isReadOnly || isReadOnlyValue}
          onChange={(value: number) => onChange(convertCurrency(value, currency, local))}
          currency={currency.key}
        />
        {secondary && (
          <Text color={textColor} variant="normal" pt={1.5} fontSize="sm" lineHeight="lg">
            {secondary.format(convertCurrency(amount, local, secondary))}
          </Text>
        )}
      </Box>
    </EntryRow>
  );
};

import { FC } from 'react';
import { Box, Switch, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { EntryRow } from '@/components/shared/EntryRow';
import { SectionHeader } from '@/components/shared/SectionHeader';
import { Segment } from '@/components/shared/Segment';
import { SelectField } from '@/components/shared/fields/SelectField';
import { useOffers } from '@/contexts/OfferContext';

import { IState } from '../interfaces/IState';
import { useCountyTaxRateSelect } from '../Croatia.utils';
import { fieldsPerDetails, numberFields } from '../constants/fields';
import { NumberField } from '@/components/shared/fields/NumberField';
import { RadioInput } from './RadioInput';
import { TYear } from '../interfaces/TYear';

const peoplesAgeValues = [
  { value: 0, label: 'hr.youngPeople.young' },
  { value: 1, label: 'hr.youngPeople.mid' },
  { value: 2, label: 'hr.youngPeople.old' },
];

const disabilityValues = [
  { value: 0, label: 'hr.disability.none' },
  { value: 1, label: 'hr.disability.some' },
  { value: 2, label: 'hr.disability.full' },
];

interface IOptionsProps {
  state: IState;
  setField(name: string, value: unknown): void;
  isOffer?: boolean;
  details?: number;
  year: TYear;
}

export const Options: FC<IOptionsProps> = ({ state, setField, isOffer, details, year }) => {
  const { t } = useTranslation();
  const { getFieldOfferState } = useOffers();
  const mladaOsobaField = getFieldOfferState('mladaOsoba');
  const ageField = getFieldOfferState('age');
  const textColor = useColorModeValue('black', 'white');
  const bg = useColorModeValue('white', 'black');

  const stope = useCountyTaxRateSelect(year);

  const taxLabel = year === 2023 ? 'hr.stopaPrireza' : 'hr.stopaPoreza';

  return (
    <Segment fields={['stopa', 'brojDjece', 'brojUzdrzavanih', 'mladaOsoba', 'age']}>
      <SectionHeader title={t('hr.grossToNet')} />
      <SelectField
        title={t(taxLabel)}
        value={state.values.stopa}
        options={stope}
        onChange={(value) => setField('stopa', value)}
        description={year === 2023 ? (t('hr.stopaPrirezaInfo') as string) : undefined}
        name="stopa"
        color={textColor}
        bg={bg}
      />
      {(numberFields as Array<{ field: keyof IState['values']; label: string; minValue: number; maxValue: number }>)
        .filter(({ field }) => fieldsPerDetails[details ?? 0].indexOf(field) !== -1)
        .filter(({ field }) => state?.values[field] !== undefined)
        .map(({ field, label, minValue, maxValue }) => (
          <NumberField
            title={t(label)}
            value={state.values[field]}
            onChange={(value) => setField(field, value)}
            name={field}
            key={field}
            minValue={minValue}
            maxValue={maxValue}
            color={textColor}
            bg={bg}
            round
          />
        ))}

      {(!isOffer || !mladaOsobaField.isHidden) && (
        <EntryRow title={t('hr.youngPerson')} description={t('hr.youngPersonInfo') as string} name="mladaOsoba">
          <Box textAlign="right">
            <Switch
              isChecked={state.flags.mladaOsoba}
              onChange={(e) => setField('mladaOsoba', e.target.checked)}
              isDisabled={mladaOsobaField.isReadOnly}
              colorScheme="brand"
            />
          </Box>
        </EntryRow>
      )}
      {(!isOffer || !ageField.isHidden) && (
        <RadioInput
          title="hr.youngPeople.label"
          description="hr.youngPeople.info"
          value={state.values.age}
          groupValue="age"
          values={peoplesAgeValues}
          setField={setField}
          color={textColor}
          bg={bg}
        />
      )}
      <RadioInput
        title="hr.disability.label"
        value={state.values.invalidnost}
        groupValue="invalidnost"
        values={disabilityValues}
        setField={setField}
        color={textColor}
        bg={bg}
      />
    </Segment>
  );
};

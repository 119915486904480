import { EntryRow } from '@/components/shared/EntryRow';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, useRadio, useRadioGroup, VStack, RadioProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

function CustomRadio({ children, radioProps }: { children: ReactNode; radioProps: RadioProps }) {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flexGrow="1">
      <input {...input} />
      <Box {...checkbox} borderWidth={0} display="inline-flex" alignItems={'center'} cursor="pointer">
        {children}
      </Box>
    </Box>
  );
}

export const RadioInput = ({
  title,
  description,
  setField,
  value,
  groupValue,
  values,
}: {
  title: string;
  description?: string;
  setField: (name: string, value: number) => void;
  value: number;
  groupValue: string;
  values: Array<{ value: number; label: string }>;
  color?: string;
  bg?: string;
}) => {
  const { t } = useTranslation();
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: groupValue,
    value: value.toString(),
    onChange: (val) => setField(groupValue, parseInt(val, 10)),
  });

  const group = getRootProps();
  const selected = value;

  return (
    <EntryRow title={t(title)} description={description && (t(description) as string)} name={groupValue}>
      <VStack {...group} alignContent="end" flexWrap={'wrap'} align="stretch">
        {values.map(({ value, label }) => {
          const radioProps = { ...getRadioProps({ value }) };

          return (
            <CustomRadio radioProps={{ ...radioProps }} key={value}>
              {selected === radioProps.value ? (
                <CheckCircleIcon w="20px" h="20px" color="brand.500" />
              ) : (
                <Box as="span" w="20px" h="20px" border="2px solid grey" borderRadius="50%" />
              )}

              <Box ml="10px">{t(label)}</Box>
            </CustomRadio>
          );
        })}
      </VStack>
    </EntryRow>
  );
};

export const optimisations = [
  { key: 'prijevoz', label: 'hr.exemptions.prijevoz' },
  { key: 'nagrada', label: 'hr.exemptions.nagrada' },

  // Not sure why "stan" was used, but we can't modify it without breaking v1 links
  { key: 'stan', label: 'hr.exemptions.hrana' },

  { key: 'podstanar', label: 'hr.exemptions.podstanar' },
  { key: 'treci', label: 'hr.exemptions.treci' },
  { key: 'ostalo', label: 'hr.exemptions.ostalo' },
  { key: 'obustave', label: 'hr.obustave' },
];

import { Step } from '@/enums/Step';

import { StepHr } from '../enums/StepHr';

export const amountFields = [
  {
    title: Step.TotalCost,
    property: 'trosak',
  },
  {
    title: StepHr.Bruto1,
    property: 'bruto1',
  },
  {
    title: Step.Net,
    property: 'neto',
  },
  {
    title: Step.Government,
    property: 'davanja',
  },
  {
    title: 'hr.maxExempt',
    property: 'odbitak',
  },
  {
    title: 'hr.dohodak',
    property: 'dohodak',
  },
  {
    title: 'hr.poreznaOsnovica',
    property: 'oporezivo',
  },
  {
    title: StepHr.OsobniOdbitak,
    property: 'odbitakCalc',
  },
  {
    title: StepHr.BrutoDoprinosi,
    property: 'brutoDoprinosi',
  },
  {
    title: StepHr.Doprinosi,
    property: 'doprinosi',
  },
  {
    title: Step.Tax,
    property: 'porez',
  },
  {
    title: StepHr.Prirez,
    property: 'prirez',
  },
  {
    title: 'hr.accountPayout',
    property: 'racun',
    highlighted: true,
  },
  {
    title: 'hr.youngPeople.label',
    property: 'povrat',
    description: 'hr.youngPeople.info',
  },
  {
    title: 'hr.naknada',
    property: 'naknada',
    description: 'hr.naknadaInfo',
  },
  {
    title: 'common.totalPay',
    property: 'totalPay',
  },
];

export const numberFields = [
  { field: 'brojDjece', label: 'hr.kids', maxValue: 9, minValue: 0 },
  { field: 'brojUzdrzavanih', label: 'hr.supporting', maxValue: 10, minValue: 0 },
];

export const fieldsPerDetails = [
  ['trosak', 'bruto1', 'neto', 'davanja', 'racun', 'povrat', 'naknada', 'brojDjece', 'brojUzdrzavanih', 'totalPay'],
  [
    'trosak',
    'bruto1',
    'neto',
    'brutoDoprinosi',
    'doprinosi',
    'porez',
    'prirez',
    'racun',
    'povrat',
    'naknada',
    'brojDjece',
    'brojUzdrzavanih',
    'totalPay',
  ],
  [
    'trosak',
    'bruto1',
    'neto',
    'odbitak',
    'odbitakCalc',
    'brutoDoprinosi',
    'doprinosi',
    'dohodak',
    'oporezivo',
    'porez',
    'prirez',
    'racun',
    'povrat',
    'naknada',
    'brojDjece',
    'brojUzdrzavanih',
    'totalPay',
  ],
];

import { ChartData } from '@/components/shared/Chart';
import { Step } from '@/enums/Step';

import { StepHr } from '../enums/StepHr';
import { IState } from '../interfaces/IState';

export const chart: ChartData<IState> = [
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalPay, Step.Perks, state.calculated.perks],
    [Step.TotalPay, StepHr.Auto, state.calculated.car?.cost ?? 0],
    [Step.TotalPay, StepHr.Neoporezivo, state.calculated.neoporezivo],
    [StepHr.Neoporezivo, StepHr.TreciStup, state.calculated.treciStup],
    [StepHr.Neoporezivo, Step.OnAccount, state.calculated.neoporezivo - state.calculated.treciStup],
    [Step.TotalPay, StepHr.Bruto1, state.calculated.bruto1],
    [Step.Net, Step.OnAccount, state.calculated.neto - state.calculated.obustave],
    [Step.Net, StepHr.Obustave, state.calculated.obustave],
    [StepHr.Bruto1, Step.Government, state.calculated.porezi + state.calculated.doprinosi],
    [
      StepHr.Bruto1,
      Step.Net,
      parseFloat((state.calculated.odbitakCalc + state.calculated.oporezivo - state.calculated.porezi).toFixed(2)),
    ],
  ],
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalPay, Step.Perks, state.calculated.perks],
    [Step.TotalPay, StepHr.Auto, state.calculated.car?.cost ?? 0],
    [Step.TotalPay, StepHr.Neoporezivo, state.calculated.neoporezivo],
    [StepHr.Neoporezivo, StepHr.TreciStup, state.calculated.treciStup],
    [StepHr.Neoporezivo, StepHr.NeoporeziviDodaci, state.calculated.neoporezivo - state.calculated.treciStup],
    [StepHr.NeoporeziviDodaci, Step.OnAccount, state.calculated.neoporezivo - state.calculated.treciStup],
    [Step.TotalPay, StepHr.Bruto1, state.calculated.bruto1],
    [Step.Net, Step.OnAccount, state.calculated.neto - state.calculated.obustave],
    [Step.Net, StepHr.Obustave, state.calculated.obustave],
    [StepHr.Bruto1, StepHr.OsobniOdbitak, state.calculated.odbitakCalc],
    [StepHr.Bruto1, StepHr.Oporezivo, state.calculated.oporezivo],
    [StepHr.OsobniOdbitak, Step.Net, state.calculated.odbitakCalc],
    [StepHr.Oporezivo, Step.Government, state.calculated.porezi],
    [StepHr.Bruto1, StepHr.Doprinosi, state.calculated.doprinosi],
    [StepHr.Doprinosi, Step.Government, state.calculated.doprinosi],
    [StepHr.Oporezivo, Step.Net, parseFloat((state.calculated.oporezivo - state.calculated.porezi).toFixed(2))],
  ],
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalCost, Step.Perks, state.calculated.perks],
    [Step.TotalCost, StepHr.Auto, state.calculated.car?.cost ?? 0],
    [Step.TotalCost, Step.TotalPay, state.calculated.total],
    [Step.TotalCost, StepHr.BrutoDoprinosi, state.calculated.brutoDoprinosi],
    [Step.TotalPay, StepHr.Neoporezivo, state.calculated.neoporezivo],
    [StepHr.Neoporezivo, StepHr.TreciStup, state.calculated.treciStup],
    [StepHr.Neoporezivo, Step.OnAccount, state.calculated.neoporezivo - state.calculated.treciStup],
    [Step.TotalPay, StepHr.Bruto1, state.calculated.bruto1],
    [Step.Net, Step.OnAccount, state.calculated.neto - state.calculated.obustave],
    [Step.Net, StepHr.Obustave, state.calculated.obustave],
    [StepHr.Bruto1, StepHr.OsobniOdbitak, state.calculated.odbitakCalc],
    [StepHr.Bruto1, StepHr.Oporezivo, state.calculated.oporezivo],
    [StepHr.OsobniOdbitak, Step.Net, state.calculated.odbitakCalc],
    [StepHr.Oporezivo, Step.Tax, state.calculated.porez],
    [StepHr.Oporezivo, StepHr.Prirez, state.calculated.prirez],
    [Step.Tax, Step.Government, state.calculated.porez],
    [StepHr.Prirez, Step.Government, state.calculated.prirez],
    [StepHr.Bruto1, StepHr.Doprinosi, state.calculated.doprinosi],
    [StepHr.Doprinosi, Step.Government, state.calculated.doprinosi],
    [StepHr.Oporezivo, Step.Net, parseFloat((state.calculated.oporezivo - state.calculated.porezi).toFixed(2))],
    [StepHr.BrutoDoprinosi, Step.Government, state.calculated.brutoDoprinosi],
  ],
];

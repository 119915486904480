import { roundMoney } from '../../../utils/formatters';
import { CAR_LEASING_DURATION, VAT } from './constants/constants';
import { IHrInputData } from './interfaces/IHrInputData';

export const LeasingType = {
  OTPInfo: 1,
  Porsche: 2,
  OTP: 3,
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LeasingType = (typeof LeasingType)[keyof typeof LeasingType];

function getOTPInfo(inputData: IHrInputData) {
  const monthlyMaintenance = inputData.values.carMaintenance + inputData.values.carOther;
  const monthlyLeasing = inputData.values.carLeasingMonthly;
  const monthlyVat = roundMoney((monthlyMaintenance + monthlyLeasing) * VAT);

  return {
    netNarav: roundMoney(inputData.values.carLeasingMonthly * (1 + VAT) * 0.2),
    monthlyVat,
    monthlyMaintenance,
    monthlyLeasing,
    personalTotalMonthly: monthlyMaintenance + monthlyLeasing + monthlyVat,
  };
}

function getPorscheLeasing(inputData: IHrInputData) {
  return {
    netNarav: roundMoney(inputData.values.carLeasingMonthly * (1 + VAT) * 0.2),
    monthlyVat: inputData.values.carMonthlyWithVat - inputData.values.carMonthlyNoVat,
    monthlyMaintenance: 0,
    monthlyLeasing: inputData.values.carMonthlyNoVat,
    personalTotalMonthly: 0,
  };
}

function getOTP(inputData: IHrInputData, legacy?: boolean) {
  const once = legacy
    ? roundMoney(inputData.values.carLeasingVatOnce / CAR_LEASING_DURATION / 2) +
      roundMoney(inputData.values.carLeasingOnce / CAR_LEASING_DURATION)
    : 0;

  const monthlyLeasing =
    inputData.values.carLeasingMonthly +
    inputData.values.carMaintenance +
    roundMoney(inputData.values.carLeasingVat / 2) +
    inputData.values.carSpecialTax +
    roundMoney(inputData.values.carInsurance / 12) +
    roundMoney(inputData.values.carKasko / 12) +
    roundMoney(inputData.values.carRegistration / 12) +
    roundMoney(inputData.values.carRegistrationVat / 12 / 2) +
    once;
  const netNarav = roundMoney(
    legacy
      ? inputData.values.carLeasingMonthly * (1 + VAT) * 0.2
      : inputData.values.carLeasingMonthly * 0.2 * (1 + VAT) + inputData.values.carSpecialTax * 0.2,
  );

  return {
    netNarav,
    monthlyVat: 0,
    monthlyMaintenance: 0,
    monthlyLeasing,
    personalTotalMonthly: 0,
  };
}

export const LEASING_FOR_TYPE: Record<
  LeasingType,
  (
    inputData: IHrInputData,
    legacy?: boolean,
  ) => {
    netNarav: number;
    monthlyVat: number;
    monthlyMaintenance: number;
    monthlyLeasing: number;
    personalTotalMonthly: number;
  }
> = {
  [LeasingType.OTPInfo]: getOTPInfo,
  [LeasingType.Porsche]: getPorscheLeasing,
  [LeasingType.OTP]: getOTP,
};

import { useMemo } from 'react';
import { COUNTIES } from './constants/calculator';

import { IHrInputData } from './interfaces/IHrInputData';
import { IHrOutputData } from './interfaces/IHrOutputData';
import { TYear } from './interfaces/TYear';
import { calculateCar } from './Cars.utils';
import { calculateSpecific } from './Payroll.utils';

export function getCalculated(inputData: IHrInputData, data: IHrOutputData, year: TYear, legacy?: boolean) {
  const specific = calculateSpecific(inputData, data);
  const car = calculateCar(inputData, data, specific, year, legacy);

  return { ...specific, ...car };
}

export function useCountyTaxRateSelect(year: TYear) {
  return useMemo(() => {
    return year !== 2023
      ? COUNTIES[year].map(({ id, name, rateLow, rateHigh }) => ({
          name: name,
          value: id,
          info: `${rateLow}% / ${rateHigh}%`,
        }))
      : COUNTIES[2023].map(({ id, name, rate, special }) => ({
          name: name === 'hr.noSurtax' ? 'hr.noSurtax' : `${name} (${rate}%) ${special ? '*' : ''}`,
          value: id,
        }));
  }, [year]);
}

export { calculatePay } from './Payroll.utils';

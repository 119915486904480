import { Heading, Box, Divider, Stack, Text, Link } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Info } from './Info';

export const SectionHeader = ({
  title,
  children,
  description,
  subtitle,
  sub,
  href,
}: {
  title: string;
  children?: ReactNode;
  description?: string;
  subtitle?: string;
  sub?: boolean;
  href?: string;
}) => (
  <>
    <Stack justifyContent="space-between" mb={4} direction={['column', 'column', 'row']} mt={sub ? 8 : 0}>
      <Box>
        <Heading as={sub ? 'h3' : 'h2'} fontSize={sub ? '2xl' : '4xl'}>
          {href ? (
            <Link href={href} target="_blank" rel="noopener noreferrer" fontWeight={400}>
              {title}
            </Link>
          ) : (
            title
          )}
          <Box ml={2} mt={-0.5} display="inline">
            <Info text={description} />
          </Box>
        </Heading>
        {subtitle && (
          <Text fontSize="md" color="gray.500">
            {subtitle}
          </Text>
        )}
      </Box>
      {children && (
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
          <Text>{children}</Text>
        </Box>
      )}
    </Stack>
    <Divider variant="title" />
  </>
);

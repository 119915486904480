import { roundMoney } from '../../../utils/formatters';
import { LEASING_FOR_TYPE, LeasingType } from './Leasing.utils';
import { calculatePay, calculateSpecific, getNontaxable } from './Payroll.utils';
import { UNTAXABLE, LIMIT_TAX_HIGH } from './constants/calculator';
import { CAR_LEASING_DURATION } from './constants/constants';
import { IHrInputData } from './interfaces/IHrInputData';
import { IHrOutputData } from './interfaces/IHrOutputData';
import { TYear } from './interfaces/TYear';

const EMPTY_CAR_DATA = {
  leasingDuration: 0,
  monthlyCompanyVat: 0,
  payNarav: 0,
  companyTotalMonthly: 0,
  netWithCar: 0,
  netDifference: 0,
  savings: 0,
  companyTotalCost: 0,
  cost: 0,
  totalPayIncrease: 0,
  monthlyLeasing: 0,
  monthlyMaintenance: 0,
};

function findTaxRatio(
  min: number,
  max: number,
  inData: {
    netNarav: number;
    inputData: IHrInputData;
    year: TYear;
    porezPrirezHigh: number;
    porezPrirezLow: number;
    monthlyVat: number;
    monthlyMaintenance: number;
    monthlyLeasing: number;
    companyTotalCost: number;
    limitTaxHigh: number;
  },
) {
  const ratio = (max + min) / 2;
  const porezPrirez = ratio * inData.porezPrirezHigh + (1 - ratio) * inData.porezPrirezLow;
  const grossNarav = roundMoney(inData.netNarav / (1 - 0.2 - (1 - 0.2) * porezPrirez));
  const monthlyCompanyVat = roundMoney(inData.monthlyVat * 0.5);
  const nontaxable = getNontaxable(inData.inputData);
  const carPayNarav =
    roundMoney(grossNarav * 0.165) +
    roundMoney(grossNarav * 0.2) +
    roundMoney(roundMoney(grossNarav * 0.8) * porezPrirez);
  const carCompanyTotalMonthly = inData.monthlyMaintenance + inData.monthlyLeasing + carPayNarav + monthlyCompanyVat;
  const newBruto2 = inData.companyTotalCost - carCompanyTotalMonthly - nontaxable;
  const newBruto1 = roundMoney(newBruto2 / (inData.inputData.flags.mladaOsoba ? 1 : 1.165));
  const newTotal = newBruto1 + nontaxable;
  const newInputData = { ...inData.inputData, values: { ...inData.inputData.values, total: newTotal } };
  const newSalary = calculateSpecific(newInputData, calculatePay(newInputData, inData.year));
  const naravPayData = calculatePay(
    {
      ...inData.inputData,
      values: {
        ...inData.inputData.values,
        total: grossNarav,
        brojDjece: 0,
        brojUzdrzavanih: 0,
      },
      options: {},
    },
    inData.year,
    Math.max(0, UNTAXABLE[inData.year] - newSalary.odbitak),
    inData.limitTaxHigh - newSalary.osnovicaLow,
  );

  if (roundMoney(naravPayData.neto) === roundMoney(inData.netNarav) || max - min < 0.0000001) {
    newSalary.trosak += carCompanyTotalMonthly;
    newSalary.totalPay += carCompanyTotalMonthly;

    return {
      ratio,
      naravPayData,
      newTotal,
      monthlyCompanyVat,
      carCompanyTotalMonthly,
      carPayNarav,
      newSalary,
    };
  } else if (naravPayData.neto > inData.netNarav) {
    return findTaxRatio(min, ratio, inData);
  } else {
    return findTaxRatio(ratio, max, inData);
  }
}

export function calculateCar(
  inputData: IHrInputData,
  data: IHrOutputData,
  originalSalary: ReturnType<typeof calculateSpecific>,
  year: TYear,
  legacy?: boolean,
) {
  if (!inputData.values.carLeasingMonthly || !inputData.values.carLeasingProvider) {
    return { ...originalSalary, car: EMPTY_CAR_DATA, originalSalary: null, narav: null };
  }

  const companyTotalCost = originalSalary.trosak;
  const leasingInfo = LEASING_FOR_TYPE[inputData.values.carLeasingProvider as LeasingType](inputData, legacy);

  console.log('leasingInfo', leasingInfo);

  const ratioData = findTaxRatio(0, data.osnovicaHigh === 0 ? 0 : 1, {
    ...leasingInfo,
    inputData,
    year,
    porezPrirezHigh: data.stopaPorezaHigh * (1 + data.stopaPrireza / 100),
    porezPrirezLow: data.stopaPorezaLow * (1 + data.stopaPrireza / 100),
    companyTotalCost,
    limitTaxHigh: LIMIT_TAX_HIGH[year],
  });

  console.log('ratioData', ratioData);

  const carNetWithCar = ratioData.newSalary?.racun || 0;

  return {
    ...ratioData.newSalary,
    narav: ratioData.naravPayData,
    originalSalary,
    car: {
      ...leasingInfo,
      leasingDuration: CAR_LEASING_DURATION,
      monthlyCompanyVat: ratioData.monthlyCompanyVat,
      payNarav: ratioData.carPayNarav,
      companyTotalMonthly: ratioData.carCompanyTotalMonthly,
      netWithCar: carNetWithCar,
      netDifference: carNetWithCar - originalSalary.racun,
      savings: leasingInfo.personalTotalMonthly + carNetWithCar - originalSalary.racun,
      companyTotalCost: companyTotalCost,
      cost: ratioData.carCompanyTotalMonthly,
      totalPayIncrease: ratioData ? ratioData.newTotal - originalSalary.total + ratioData.carCompanyTotalMonthly : 0,
    },
  };
}

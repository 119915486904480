import { Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { perks } from '@/constants/perks';
import { Country } from '@/interfaces/Country';
import { isInReadonlyArray } from '@/utils/is-in-readonly-array';

import { AddOns } from './AddOns';

export const Perks = ({
  state,
  setField,
  country,
  skipPerks,
}: {
  country: Country;
  state: { options?: Record<string, { flag: boolean; value: number }> };
  setField(name: string, value: unknown, enabled?: boolean): void;
  skipPerks?: Array<string>;
}) => {
  const { t } = useTranslation();
  const availablePerks = perks.filter(
    ({ countries, key }) => isInReadonlyArray(country, countries) && !skipPerks?.includes(key),
  );

  return (
    <AddOns title={t('common.perks.text')} items={availablePerks} state={state} setField={setField}>
      <Link
        // eslint-disable-next-line max-len
        href="https://infinum.com/handbook/general/you-as-an-employee-at-infinum/benefits-and-discounts/benefits-and-discounts-croatia"
        isExternal
      >
        {t('common.details.text')}
      </Link>
    </AddOns>
  );
};

export enum StepHr {
  Neoporezivo = 'hr.taxExempt',
  Bruto1 = 'hr.bruto1',
  TreciStup = 'hr.treciStup',
  NeoporeziviDodaci = 'hr.neoporeziviDodaci',
  Oporezivo = 'hr.oporezivo',
  OsobniOdbitak = 'hr.osobniOdbitak',
  Doprinosi = 'hr.doprinosi',
  BrutoDoprinosi = 'hr.brutoDoprinosi',
  Prirez = 'hr.prirez',
  Auto = 'cars.section',
  Obustave = 'hr.obustave.title',
}

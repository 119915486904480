import { Box, chakra, NumberInput, NumberInputField, useColorModeValue } from '@chakra-ui/react';

import { useOffers } from '@/contexts/OfferContext';
import { EntryRow } from '@/components/shared/EntryRow';

const NumberFieldInput = chakra(NumberInputField, {
  baseStyle: {
    border: 0,
    textAlign: 'right',
    fontSize: 'xl',
    fontWeight: 'bold',
    maxW: 200,
    h: '44px',
    pr: 2,
  },
});

export const NumberField = ({
  title,
  value,
  name,
  maxValue,
  minValue,
  round,
  onChange,
  color,
}: {
  title: string;
  value: number;
  name?: string;
  maxValue?: number;
  minValue?: number;
  round?: boolean;
  onChange(valueAsNumber: number): void;
  color?: string;
  bg?: string;
}) => {
  const { getFieldOfferState } = useOffers();
  const { isReadOnly, isReadOnlyValue } = getFieldOfferState(name);
  const bgColor = useColorModeValue('gray.200', 'gray.600');

  const handleOnChange = (value: string) => onChange(parseFloat(value) || 0);

  return (
    <EntryRow title={title} name={name}>
      <Box alignItems="flex-end" display="flex" flexDirection="column">
        <NumberInput
          min={minValue ?? 0}
          max={maxValue}
          defaultValue={value}
          // TODO: extract and document this business rule, and/or limit the set of possible values
          // for 'name' prop (define the type based on business domain)
          step={name === 'yearsBonus' ? 0.5 : 1}
          onChange={handleOnChange}
          allowMouseWheel
          isDisabled={isReadOnly || isReadOnlyValue}
          precision={round ? 0 : 2}
        >
          <NumberFieldInput bg={bgColor} color={color} />
        </NumberInput>
      </Box>
    </EntryRow>
  );
};
